import {createGlobalStyle} from 'styled-components'

// Global styles for admin/dashboard/editor
export const AdminGlobalStyle = createGlobalStyle`
  /* Placeholder for CSS variables */
  :root {
    --some-css-variable: #ff0000;
  }

  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
	  -moz-osx-font-smoothing: grayscale;
  }
  // richtext editor
  [forestry-class="pm-align--right"i], [class="pm-align--right"i] {
    text-align: right;
  }
  [forestry-class="pm-align--center"i], [class="pm-align--center"i] {
    text-align: center;
  }
  [forestry-class="pm-align--left"i], [class="pm-align--left"i] {
    text-align: left;
  }
  [forestry-class="pm-align--justify"i], [class="pm-align--justify"i] {
    text-align: justify;
  }

  #modal-root [class^="FormBody"] {
    height: 80vh;
  }

`

const viewportSize = {
  mobile: '640px',
  tablet: '1024px',
  laptop: '1200px',
  desktop: '1440px',
}

export const breakpoint = {
  mobile: `${viewportSize.mobile}`,
  tablet: `${viewportSize.tablet}`,
  laptop: `${viewportSize.laptop}`,
  desktop: `${viewportSize.desktop}`,
}

// Typography
const fontSize = {
  large: '18px',
  small: '16px',
}

export const baseFontSize = {
  large: `${fontSize.large}`,
  small: `${fontSize.small}`,
}

const paragraphLineHeight = '1.5'
const paragraphTextRendering = 'optimizeLegibility'
const smallFontSize = '80%'

// Space
const margin = '1rem'
const padding = '1rem'
const sectionSpace = '5.56rem'
const sectionSpaceIntro = '6.94rem'
const sectionSpaceAnnouncement = '3.06rem'
const paragraphSpace = '1rem'

// Contact Form
const contactFocusColor = '#005ed1'
const contactFocusBackgroundColor = '#e0eeff'
const contactAlertColor = '#ff9400'
const contactAlertColorBackground = '#feefca'
const contactSuccessColor = '#008000'
const contactSuccessColorBackground = '#ccffc9'
const contactAlertImage =
  'https://d1l9wtg77iuzz5.cloudfront.net/assets/3164/119819/original.svg'
const contactCheckMarkImage =
  'https://d1l9wtg77iuzz5.cloudfront.net/assets/3164/119341/original.svg'
const contactInputBackground = 'rgba(255, 255, 255, 0.4) !default'
const contactBoldInputColor = '#000 !default'
const contactInputColor = '#000 !default'
const contactLabelColor = '#fff !default'
const contactInputBorderColor = '#ccc !default'
const contactSuggestionColor = '#000 !default'
const contactSuggestionBackground = '#fff !default'

// Front Stack
const frontStackWidth = '1200px'
const frontStackBoxShadowOffsetX = '0'
const frontStackBoxShadowOffsetY = '0'
const frontStackBoxShadowBlurRadius = '10px'
const frontStackBoxShadowSpreadRadius = '0'
const frontStackBoxShadowColor = 'rgba(0, 0, 0, 0.05)'
const frontStackOverlapHeight = '2rem'
const frontStackImageWidth = '1100px'

// Back Stack
const backStackImageWidth = '1680px'
const backStackWideObjectTextShadow = '1px 2px 2px #000'

// Main Navigation
const mainNavHeight = '170px'
const mainNavHeightMobile = '45px'
const mainNavHeightShrink = '80px'
const mainNavHeightD = '110px'
const mainNavHeightDMobile = '60px'
const mainNavHeightDShrink = '85px'

// Logo
const LogoImageHeight = '110px'
const LogoImageWidth = '360px'
const LogoImageHeightMobile = '55px'

// Top Bar
const topBarHeight = '40px'

// List
const listStyleType = 'disc'
const listStyleOrderType = 'decimal'
const listStylePosition = 'outside'
const listMargin = '1rem'
const listSideMargin = '1.5rem'
const listSideOrderMargin = '1.25rem'
const listStyleOrderPadding = '0.25rem'
const listNestedSideMargin = '1.25rem'
const listItemMargin = '0.75rem'
const listRuledPadding = '0.5rem'
const listColumnCount = '2'
const listColumnGap = '4rem'
const listColumnWidth = '10rem'
const listColumnRuleWidth = '1px'
const listColumnRuleStyle = 'solid'
const listColumnItemMargin = '.75rem'
const listDefinitionMargin = '1rem'
const listDefinitionTermMargin = '0.25rem'

// Global styles for client sites
export const SitesGlobalStyle = createGlobalStyle`
  html {
    font-family: sans-serif;

    /* Prevent iOS and IE text size adjust after device orientation change, without disabling user zoom. */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  /* Remove default margin and padding 8 */
  body {
    margin: 0;
    padding: 0;
  }

  /* Correct block display not defined for any HTML5 element */
  figcaption,
  figure,
  header,
  main,
  nav,
  section {
    display: block;
  }

  /* Remove the gray background color from active links */
  a {
    background-color: transparent;
  }

  /* Improve readability of focused elements when they are also in an active/hover state. */
  a:active,
  a:hover {
    outline: 0;
  }

  /* Remove border when inside a element */
  a img {
    border: 0;
  }

  /* Correct overflow not hidden in IE */
  svg:not(:root) {
    overflow: hidden;
  }

  /* Address differences between Firefox and other browsers. */
  hr {
    box-sizing: content-box;
    height: 0;
  }

  button,
  input,
  select,
  textarea {
    /* Correct color not being inherited. Known issue: affects color of disabled elements. */
    color: inherit;

    /* Correct font properties not being inherited. */
    font: inherit;

    /* Address margins set differently in Firefox, Safari, and Chrome. */
    margin: 0;
  }

  /* Address overflow set to hidden in IE. */
  button {
    overflow: visible;
  }

  /* Address inconsistent text-transform inheritance for button and select. */
  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    /* Avoid the WebKit bug in Android 4.0.* where (2) destroys native audio and video controls. */
    -webkit-appearance: button;

    /* Correct inability to style clickable input types in iOS. */
    cursor: pointer;
  }

  /* Re-set default cursor for disabled elements. */
  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  /* Remove inner padding and border in Firefox. */
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /* Address Firefox setting line-height on input using !important in the UA stylesheet. */
  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    /* Address box sizing set to content-box in IE. */
    box-sizing: border-box;

    /* Remove excess padding in IE */
    padding: 0;
  }

  /* Remove default vertical scrollbar in IE. */
  textarea {
    overflow: auto;
  }

  /* Remove most spacing between table cells. */
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  html {
    font-size: 100%;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
  }

  textarea {
    height: auto;
    min-height: 50px;
    border-radius: 3px;
  }

  select {
    width: 100%;
    border-radius: 3px;
  }

  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    padding: 0;
    border: 0;
    border-radius: 3px;
    line-height: 1;
  }
`

export const SitesGridStyle = createGlobalStyle`
  .row {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
  }

  .row.expanded {
    max-width: none;

    .column {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .row.collapse > .column{
    padding-left: 0;
    padding-right: 0;
  }

  .column {
    flex: 1 1 0px;
    padding-left: $column-gutter;
    padding-right: $column-gutter;

    @media screen and (max-width: ${breakpoint.small}) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &.shrink {
      flex: 0 0 auto;

      @media screen and (max-width: ${breakpoint.small}) {
        flex: 0 0 100%;
      }
    }

    &:only-child {
      flex: 0 0 66.6667%;;
      max-width: 66.6667%;

      @media screen and (max-width: ${breakpoint.small}) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .column.row.row {
    float: none;
  }

  .row .column.row.row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
`
