import Head from 'next/head'
import {AdminGlobalStyle} from '../../styles'
import {LoginBox, Main} from '../../styles/home'
import {NextPage} from 'next'
import {Button, Loading, Spacer, Text} from '@nextui-org/react'
import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import {useCASLogin} from '@/src/authentication/login'

export default function Home() {
  const {lt} = useRouter().query
  useCASLogin({lt})

  const [isPerformingAuthAction, setIsPerformingAuthAction] = useState('')

  const loginWithEinstein = () => {
    setIsPerformingAuthAction('einstein')
  }

  const loginWithGoogle = () => {
    setIsPerformingAuthAction('google')
    //TODO: Implement google login
  }

  const signUp = () => {
    setIsPerformingAuthAction('signup')
    //TODO: implement signup?
  }

  useEffect(() => {
    if (lt) {
      setIsPerformingAuthAction('einstein')
    }
  }, [lt])

  return (
    <Main>
      <Head>
        <title>Lucid</title>
      </Head>

      <LoginBox>

        <Text h3 color="primary">Lucid 3.0</Text>
        <Text h1>Login and Signup</Text>

        <Text color={'accent'} style={{
          marginBottom: 0,
          paddingBottom: 0
        }}>Continue with an account below or sign up</Text>
        <Spacer y={1}></Spacer>

        <Button
          size={'lg'}
          style={{
            width: '100%'
          }}
          onClick={loginWithEinstein}>
          {isPerformingAuthAction === 'einstein' ?
            <Loading color="currentColor" size="sm"/> : 'Continue with Einstein'}
        </Button>

        <Spacer y={0.5}></Spacer>
        <Button
          size={'lg'}
          style={{
            width: '100%'
          }}
          disabled
          onClick={loginWithGoogle}
        >
          {isPerformingAuthAction === 'google' ?
            <Loading color="currentColor" size="sm"/> : 'Continue with Google'}

        </Button>
        <Spacer y={1}></Spacer>

        <Button
          light
          disabled
          color="primary"
          onClick={signUp}
          auto>
          {isPerformingAuthAction === 'signup' ?
            <Loading color="currentColor" size="sm"/> : 'Don\'t have an account? Sign up'}

        </Button>

      </LoginBox>
    </Main>
  )
}

Home.getLayout = function getLayout(page: NextPage) {
  return (
    <>
      <AdminGlobalStyle/>
      {page}
    </>
  )
}
