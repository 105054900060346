import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {NewUser, SessionUser, User} from '@/components/shared/types'
import {lucidDataFetcherV2} from '../graphql/fetchers'
import {CREATE_USER} from '../graphql/mutations'
import {SEARCH_USERS} from '../graphql/queries'

const handleCreateUser = async (user: NewUser) => {
  const {name, email, role_id} = user
  const variables = {
    newUser: {
      name,
      email,
      role_id,
    }
  }
  const {data: {addUser: newUser}} = await lucidDataFetcherV2<User>(CREATE_USER, variables)
  return newUser
}

const searchExistingUser = async (email: string) => {
  const variables = {
    searchUserInput: {
      query: email,
      searchBy: 'EMAIL'
    },
    take: 1
  }
  const {data: {searchUsers: users}} = await lucidDataFetcherV2<User[]>(SEARCH_USERS, variables)
  return users.length > 0 ? users[0] : null
}

export const useCASLogin = (query: { lt: any }) => {
  const router = useRouter()
  const {lt} = query

  useEffect(() => {
    const verifyToken = async (token: string) =>
      await fetch('/api/CASVerifyToken', {
        method: 'POST',
        body: JSON.stringify({token}),
      })

    const loginRedirect = async () => {
      const res = await fetch('/api/env-host')
      const data = await res.json()
      document.location.href = `${process.env.NEXT_PUBLIC_EINSTEIN_AUTH_SERVER}/login?dest_url=${data.host}/login`
    }

    // is user logged in?
    const user = localStorage.getItem('lucidAdminToken')

    //redirect if loggedIn
    if (user) router.push('/sites') // TODO: redirect to previous url and use /users as a fallback

    const storeUser = async (userData: SessionUser) => {
      const email: string = userData.email
      const name: string = userData.user.name

      try {
        const existingUser = await searchExistingUser(email)

        let storedUser: NewUser

        if (existingUser) {
          storedUser = existingUser
        } else {
          storedUser = await handleCreateUser({name, email})
        }
      } catch (error) {
        console.error('Error creating user:', error)
      }
    }

    if (!lt) {
      loginRedirect()
    } else {
      verifyToken(lt).then((rawData) =>
        rawData.json().then((data) => {
          storeUser(data)

          const user = {
            email: data.email,
            name: data.user.name,
            sessionId: data.session_id,
            canGhostLogin: true,
          }
          localStorage.setItem('lucidAdminToken', JSON.stringify(user))
          router.push('/sites')
        })
      )
    }
  }, [lt, router])
}
